<template>
  <div id="main">
    <el-main>
      <div class="section" v-if="type">
        <div class="handle">找回密码</div>
        <div class="backLogin" @click="backLogin()">返回首页</div>
        <div style="height: 70px"></div>
        <div style="margin-bottom: 30px; color: #ee7602">
          系统会将重置后的密码发送到您注册时的邮箱及手机里。请及时查收
        </div>
        <div class="inputBox">
          <div>证件号码<span style="color: #ee7602">（*）</span></div>
          <el-input v-model="IdNum" @focus="onFocus"></el-input>
          <div class="info_txt" v-if="isShowId">证件号码不能为空</div>
        </div>
        <div></div>
        <div style="margin: auto; padding-top: 10px; padding-left: 25px;color: #999999;">
          <div>身份证最后一位为X时，请输入大写【X】</div>
          <div>台胞证号若含有括号,需要用中文括号</div>
        </div>
        <div class="btnBox">
          <el-button style="margin-left: 190px;" @click="next()">
            下一步
          </el-button>
        </div>
      </div>

      <div class="section" v-if="!type">
        <div style="font-size: 28px; margin-top: 60px">找回密码</div>
        <div class="title">
          密码发送至
        </div>
        <div class="testBox">
          <div>你在中学标准学术能力测试报名系统填写的电子邮件和手机</div>
          <div>如果邮箱未收到邮件可以在垃圾邮件的文件夹查看。</div>
          <div class="txt_color" v-if="email">Email: {{ email }}</div>
          <div class="txt_color" v-if="phone">Tel: {{ phone }}</div>
        </div>
        <div class="btnBox" @click="backLogin()" style="text-align:center;">
          <el-button style="background: #563279; color: #fff;"
            >返回首页</el-button
          >
        </div>
      </div>
    </el-main>
    <dialog-box
      :dialogVisibleFu="dialogVisibleFu"
      @closeDialog="closeDialog"
    ></dialog-box>
  </div>
</template>

<script>
import DialogBox from "c/index/changePwd/DialogBox";
import { sendPassMsg } from "../../../request/index/login";
export default {
  data() {
    return {
      IdNum: "",
      isShowId: false,
      dialogVisibleFu: false,
      type: false,
      email: "",
      phone: "",
    };
  },
  created() {
    this.$emit('getStatus',1)
    if (
      window.localStorage.getItem("f_forgetpwd") == null ||
      window.localStorage.getItem("f_forgetpwd") == 0
    ) {
      this.type = true;
    } else {
      this.type = false;
      this.email = window.localStorage.getItem("f_email");
      this.phone = window.localStorage.getItem("f_phone");
    }
  },
  methods: {
    closeDialog(value) {
      this.dialogVisibleFu = value;
      this.$router.replace("/login");
    },
    backLogin() {
      window.localStorage.removeItem("f_forgetpwd");
      window.localStorage.removeItem("f_email");
      window.localStorage.removeItem("f_phone");
      this.$router.replace("/login");
    },
    onFocus() {
      this.isShowId = false;
    },
    async next() {
      if (this.IdNum == "") {
        return (this.isShowId = true);
      }
      const { data: res } = await sendPassMsg({
        id_card: this.IdNum,
      });
      console.log(res);
      if (res.code !== 200) {
        if (res.code == 400209 || res.code == 400210) {
          return (this.dialogVisibleFu = true);
        }
        return this.$message.error(res.message);
      }
      this.email = res.data.email;
      this.phone = res.data.phone;
      window.localStorage.setItem("f_forgetpwd", 1);
      window.localStorage.setItem("f_email", res.data.email);
      window.localStorage.setItem("f_phone", res.data.phone);
      this.type = false;
      this.isShowId = false;
    },
  },
  components: {
    DialogBox,
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  min-height: 80%;
  overflow: auto;
  .el-main {
    max-width: 1300px;
    margin: 6px auto;
    padding: 20px 50px;
  }
  .section {
    position: relative;
    max-width: 1024px;
    margin: auto;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .handle {
      width: 100%;
      text-align: center;
      font-size: 28px;
      padding-top: 20px;
    }
    // 返回首页
    .backLogin {
      position: absolute;
      right: 40px;
      top: 35px;
      color: #4c4c4c;
      font-size: 14px;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        color: #563279;
      }
    }
    .inputBox {
      position: relative;
      width: 60%;
      margin: auto;
      display: flex;
      align-items: center;
      padding-left: 9%;
      div {
        width: 100px;
      }
      .info_txt {
        width: 150px;
        position: absolute;
        left: 500px;
        color: #ee7602;
      }
    }
    .btnBox {
      width: 60%;
      margin-top: 50px;
    }

    .title {
      font-size: 20px;
      color: #563279;
      width: 48%;
      text-align: left;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .testBox {
      width: 48%;
      font-size: 16px;
      margin-bottom: 50px;
      div {
        line-height: 35px;
        color: #333333;
      }
      .txt_color {
        color: #ee7602;
      }
    }
  }
}
::v-deep .el-input {
  .el-input__inner {
    width: 300px;
    height: 50px;
    line-height: 50px;
    border-radius: 0;
    &:focus {
      border-color: #563279;
    }
  }
}
::v-deep .el-button {
  background: #563279;
  color: #fff;
  width: 300px;
  height: 48px;
  // margin: 0;
}
</style>
