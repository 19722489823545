<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="400px"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <div class="info_3">
      1、初始密码为身份证号的后六位
    </div>
    <div class="info_3">
      2、若使用初始密码无法登录，请联系考务：service@thussat.com
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogBox",
  props: {
    dialogVisibleFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleFu,
    };
  },
  methods: {
    confirm() {
      this.cancelBtn();
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeDialog", this.dialogVisible);
    },
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
::v-deep .el-dialog__header {
  height: 70px !important;
  line-height: 70px !important;
  padding: 0;
}
::v-deep .el-dialog__footer {
  margin: 0;
  padding: 10px 0 30px !important;
  .el-button {
    margin: 0 auto !important;
    display: block;
  }
}
.info_3 {
  font-size: 16px !important;
  text-align: left !important;
  margin-bottom: 5px;
}
</style>
