import {request} from "./network";

// 登录
export function login(data) {
    return request({
        method: "post",
        url: "/web/auth/login",
        data,
    });

}

//退出登录
export function logout(data) {
    return request({
        method: "post",
        url: "/web/auth/loginOut",
        data,
    });
}

//修改密码
export function changePass(data) {
    return request({
        method: "post",
        url: "/web/auth/changePass",
        data,
    });
}

//找回密码
export function sendPassMsg(data) {
    return request({
        method: "post",
        url: "/web/auth/sendPassMsg",
        data,
    });
}

